
.container{
  display: flex;
  justify-content: center;
  align-items: center;
}
img{
  display: initial !important;
}
.header{
  text-align: center;
}
.normal-txt{
  line-height: 30px;
}

/* li{
  padding-left: 20px;
} */
.red-txt{
padding-left: 20px;
font-weight: 400 !important;
/* font-size: 24px !important; */
line-height: 28px;
color: #FF3A34 !important;
}
.normal-text{
  font-family: 'Rubik';
font-style: normal;
font-weight: 400 !important;
/* font-size: 30px !important; */
line-height: 36px;
text-transform: capitalize;
color: #454555;
}
.des{
  font-family: 'Inter';
font-style: normal;
font-weight: 400 !important;
font-size: 16px;
line-height: 30px !important;
letter-spacing: 0.2px;
color: #414656;
}
.heading{
  font-family: 'Rubik';
font-style: normal;
font-weight: 400 !important;
text-transform: capitalize;
color: #000000
}
.bg-yellow{
  background-color: yellow;
}
.green-txt{
  color: #008A64;
  background: rgba(109, 250, 205, 0.6);
  border-radius: 4px;
}
.blue-txt{
  color: blueviolet;
}
.copyright{
 color: rgb(170, 170, 188);
 text-align: center;
}

table, th, td {
  padding: 4px;
  border: 1px solid black;
  border-collapse: collapse;
}
.top-heading{
font-family: 'Rubik';
font-style: normal;
font-weight: 500;
/* font-size: 30px; */
line-height: 36px;
text-transform: capitalize;
color: #454555;
}
.top-sub-heading{
font-family: 'Rubik';
font-style: normal;
font-weight: 400 !important;
/* font-size: 20px !important; */
line-height: 24px;
text-transform: capitalize;
color: #AAAABC;
}
.card{
background: #FFFFFF;
box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.04), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0238443), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0161557);
border-radius: 24px;
}
.most-popular{
font-weight: 400;
font-size: 16px !important;
line-height: 19px;
color: #FFFFFF;
background: #31468E;
transform: rotate(-41.9deg);
width: 100px;
height: 20px;
margin-top: 30px;
margin-bottom: -35px;
}
.Best-value{
  font-weight: 400;
font-size: 16px !important;
line-height: 19px;
color: #FFFFFF;
background: #DB6CB1;
transform: rotate(-41.9deg);
width: 100px;
height: 20px;
margin-top: 30px;
margin-bottom: -35px;

}
